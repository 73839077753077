import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Image from "next/legacy/image";
import useScreenSize from "../../../components/customHooks/useScreenSize";

const Item = (props) => {
  const screenLayoutType = useScreenSize();
  const fontSize = screenLayoutType === "smallScreen" ? "18px" : "28px";
  const lineHeight = screenLayoutType === "smallScreen" ? "36px" : "51px";
  const marginBottom =
    screenLayoutType === "smallScreen" || "mediumScreen" ? "0" : "1.4rem";
  const marginTop = screenLayoutType === "smallScreen" ? "0" : "1.4rem";
  const { description, image, title, name } = props.item;
  return (
    <Box height={426} display="flex" alignItems="center" role="article">
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{
          maxHeight: "inherit",
        }}
      >
        <Grid item>
          <Box maxWidth={920} p="1rem" mb={marginBottom} mt={marginTop}>
            <Typography
              align="center"
              sx={{
                color: "white",
                fontFamily: "Poppins",
                fontWeight: "600",
                letterSpacing: "1px",
                fontSize,
                lineHeight,
              }}
            >
              {description}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box mb="1.4rem">
            <Image
              src={image}
              className="item-carousel-img"
              alt={title}
              width="82px"
              height="82px"
              objectFit="cover"
            />
          </Box>
        </Grid>
        <Grid item sx={{ textAlign: "center" }}>
          <Box mb="0.8rem">
            <Typography
              variant="span"
              align="center"
              sx={{
                color: "white",
                letterSpacing: ".15px",
                fontStyle: "italic",
                display: "block",
              }}
            >
              {name}
            </Typography>
            <Typography
              variant="span"
              align="center"
              sx={{
                color: "white",
                letterSpacing: ".15px",
                fontStyle: "italic",
                display: "block",
                marginTop: "0.4rem",
              }}
            >
              {title}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Item;
