import React from "react";
import Carousel from "react-material-ui-carousel";
import { Grid, Box } from "@mui/material";
import Item from "./Item";
import items from "./items.json";

const CarouselComponent = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      height="100%"
      role="region"
      aria-label="Image Carousel"
    >
      <Box width="100%">
        <Carousel
          indicatorIconButtonProps={{
            style: {
              width: 10,
              height: 10,
              backgroundColor: "#D7E9E5",
              margin: "3px",
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: "#FFF",
              backgroundColor: "#FFF",
              margin: "3px",
            },
          }}
          navButtonsAlwaysInvisible={true}
          animation="slide"
          swipe={true}
          interval={8000}
          IndicatorIcon={
            <span
              style={{
                padding: "8px",
              }}
            />
          }
          aria-roledescription="Image Carousel"
          aria-label="Gallery"
        >
          {items.map((item, i) => (
            <Item key={i} item={item} />
          ))}
        </Carousel>
      </Box>
    </Grid>
  );
};

export default CarouselComponent;
